@import "typography", "mixins", "global", "components", "forms", "layout"
@import "kitchens/all"


html
    box-sizing: border-box

*
    &,
    &::after,
    &::before
        box-sizing: inherit

