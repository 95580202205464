@import "../sass/color-schemes"
@import "./mixins"

fieldset
    border: none
    padding: 0
    margin: 0

    & > .list-item
        padding-left: 0
        margin-left: 5%
        width: 95%

        @include bp(820up)
            width: 50%
            margin-left: 0
            float: left
            clear: none

            &:nth-of-type(2n+1)
                @include colorAttr("border-right", $lightgray, "1px solid")
                padding-left: 0
                padding-right: 3%
                clear: left

            &:nth-of-type(2n+2)
                @include colorAttr("border-bottom", $lightgray, "1px solid")
                border-right: none
                float: left
                padding-right: 0
                padding-left: 3%
                clear: none

            &:last-of-type
                border-bottom: none

            &.full-width
                padding-left: 0
                padding-right: 0
                border-left: none
                border-right: none
                clear: left
                float: none!important

    & + .list-item
        @include colorAttr("border-top", $lightgray, "1px solid")

    & + .list-item-header
        border-top: none

    &.cc-information

        & > .list-item
            margin: 0
            width: 100%

            +bp(820up)
                width: 50%

label
    font-size: rem(15)
    line-height: 1.2
    width: 100%
    display: inline-block

    span.muted
        @include colorAttr("color", $gray)

.quantity-label
    line-height: 1.8
    width: auto
    display: inline

input.error, .error > input
    color: shade(red, 15%)
    border-color: shade(red, 15%)!important

.form-message.error
    color: shade(red, 15%)

.error-message
    @include clearfix
    background: tint(red, 55%)
    display: block
    border-radius: 1px
    border: 1px solid shade(red, 45%)
    color: shade(red, 45%)
    padding: rem(2) rem(12) rem(3)
    margin-bottom: rem(18)
    margin-right: 3.3%
    margin-left: 3.3%

    +bp(820up)
        margin-left: 0
        margin-right: 0

.success-message
    @include clearfix
    background: tint(green, 55%)
    display: block
    border-radius: 1px
    border: 1px solid shade(green, 45%)
    color: shade(green, 45%)
    padding: rem(2) rem(12) rem(3)
    margin-bottom: rem(18)
    margin-right: 3.3%
    margin-left: 3.3%

    +bp(820up)
        margin-left: 0
        margin-right: 0

input[type=radio],
input[type=checkbox]
    border: 0
    clip: rect(0 0 0 0)
    height: 1px
    margin: -1px
    overflow: hidden
    padding: 0
    position: absolute
    width: 1px

    & + label
        padding-left: 1.6em
        &:before
            content: ""
            height: 1.2em
            width: 1.2em
            @include colorAttr("color", $gray)
            @include colorAttr("border", currentColor, "2px solid")
            @include colorAttr("background-color", white)
            display: inline-block
            margin: 0 0.4em 0.2em -1.6em
            vertical-align: middle
            transition: background-color 150ms ease-in-out, box-shadow 250ms ease-in-out, border-color 250ms ease-in-out

    &:checked
        & + label
            &:before
                @include colorAttr("border-color", currentColor)
                @include colorAttr("background-color", currentColor)
                @include colorAttr("color", callout)

input[type=radio]

    & + label

        &:before
            border-radius: 50%
            box-shadow: inset 0 0 0 0 rgba(255,255,255,1)
            transition: box-shadow 450ms easeOutBack

    &:checked

        & + label

            &:before
                box-shadow: inset 0 0 0 3px rgba(255,255,255,1)

    &:focus

        & + label

            &:before
                box-shadow: inset 0 0 0 3px rgba(255,255,255,1), 0 0 0 3px tint($red, 50%)

input[type="checkbox"]
    & + label
        &:before
            content: url(../img/icons-checkmark.svg)
            border-radius: 2px

    &:focus
        & + label
            &:before
                box-shadow: 0 0 0 3px tint($red, 50%)

input[type=number],
input[type=text],
input[type=date],
input[type=time],
input[type=password],
input[type=tel],
input[type=email],
textarea
    @include colorAttr("border", $gray, "1px solid")
    border-radius: 1px
    font-size: rem(14)
    line-height: 1.428571429
    font-family: "Inter"
    -webkit-appearance: none

    &:focus
        @include colorAttr("border", callout, "1px solid")
        box-shadow: 0px 0px 6px 0px rgba(243,87,0,0.20)
        outline: none

input[type=text],
input[type=date],
input[type=number],
input[type=time],
input[type=password],
input[type=tel],
input[type=email],
textarea
    width: 100%
    margin: rem(8) 0
    padding: rem(11) rem(12)

input.datetime-input
    display: inline-block
    width: 47.5%

    &.time-input
        float: right

textarea
    height: auto

input.quantity-stepper-input
    position: relative
    text-align: center
    padding: 0

    &:before
        position: absolute
        content: "url(../img/icons-quantity-minus.svg)"
        top: 0
        bottom: 0
        left: rem(-46)
        width: rem(46)

input[type=number]::-webkit-inner-spin-button
    -webkit-appearance: none

.quantity-selector
    float: right

.quantity-stepper
    width: rem(46)
    height: rem(30)
    @include colorAttr("border", #DFE7EC, "1px solid")
    display: inline-block
    margin: -1px 0 0
    vertical-align: middle
    text-align: center
    cursor: pointer

    &.quantity-plus
        padding-top: 6px

    & + input
        height: rem(30)
        margin: 0
        width: rem(46)
        border-radius: 0
        @include colorAttr("border", #DFE7EC, "1px solid")

.quantity-icon
    path
        @include colorAttr("fill", #7e848f)

    rect
        @include colorAttr("fill", #7e848f)

.location-bar-container
    width: 100%
    padding: 0

input[type=text].location-field
    width: 100%
    background-image: url(../img/icons-location.svg)
    background-repeat: no-repeat
    background-position: 12px
    padding-left: em(32,14)

    &.header-location-field
        padding: em(7,14) em(12,14) em(7,14) em(32, 14)
        margin: 0
        height: rem(36)

input[type=text].cc-field
    width: 100%
    text-align: left

input[type=number].cc-date
    width: 48%
    text-align: left

input.cc-cvc
    background-image: url(../img/icons-lock.svg)
    background-repeat: no-repeat
    background-position: 12px 13px
    padding-left: em(38,14)

input[id=promo-code]
    background-image: url(../img/icons-tag.svg)
    background-repeat: no-repeat
    background-position: 12px 13px
    padding-left: em(38,14)

.list-item-promo-code
    position: relative

    .promo-code-apply-link
        font-size: 16px
        position: absolute
        top: 1.8rem
        right: 0
        margin-right: 2.5rem
        cursor: pointer

        +bp(640up)
            margin-right: 2rem
            text-align: right
            top: 2.4rem

        +bp(820up)
            margin-right: 1rem

.order-submit
    display: inline-block
    width: 100%
    @include clearfix
    // position: fixed
    z-index: 999
    // bottom: 0
    // height: rem(54)
    padding: 15px
    font-size: rem(15)

    @include bp(960up)
        position: static
        padding: 0 15px

    @include bp(1000up)
        padding: 0

    @media screen and (max-height: 400px)
        z-index: 100
        margin: 0

    &.checkout
        margin-bottom: 0

        +bp(640up)
            margin-bottom: 14px
            .btn-cta-full
                width: 100%
                max-width: 100%
                min-width: 100%
                margin-left: 0

.form-message
    font-size: rem(12)
    @include colorAttr("color", $midgray)
    line-height: em(15,12)
    margin-top: 0

    a
        @include colorAttr("color", callout)
        text-decoration: none

input[type=text].share-link-field
    display: block
    margin: 0 auto
    width: 90%
    background-image: url(../img/icons-link.svg)
    background-repeat: no-repeat
    background-position: 12px
    padding-left: em(32,14)

.order-submit

    @include bp(700up)



.group-code
    position: relative

    label
        text-transform: uppercase
        @include colorAttr("color", #515455)
        font-weight: bold
        font-size: rem(12)

    .info
        width: rem(18)
        height: rem(18)
        position: absolute
        right: 0
        top: 0
        cursor: pointer
        display: block

        img
            width: 100%
            display: block

    input[type="text"]
        border-top: 0
        border-left: 0
        border-right: 0
        outline: 0
        box-shadow: none!important
        font-weight: normal
        font-size: rem(20)
        padding-left: 0
        padding-right: rem(40)

        &:valid + .submit svg polygon
            @include colorAttr("fill", callout)

    .submit
        display: block
        cursor: pointer
        border: 0
        outline: 0
        background-color: transparent
        position: absolute
        right: 0
        bottom: rem(20)

small
    display: block
    margin-top: rem(5)
    padding-left: rem(4)
    font-size: rem(12)
    @include colorAttr("color", $midgray)
    line-height: 1.4

.radio-select
    cursor: pointer

.map-input
    position: relative

    span
        position: absolute
        left: rem(15)
        top: rem(22)
    input
        padding-left: rem(35)

.disclaimer
    font-size: rem(12)
    font-weight: normal
    @include colorAttr("color", $gray)
    margin-bottom: rem(40)

.share-input
    position: relative
    margin-bottom: rem(10)

    .icon
        position: absolute
        top: rem(15)
        left: rem(15)
    input
        padding-left: rem(40)
        margin: 0
    button
        position: absolute
        right: 0
        top: 1px
        right: 1px
        bottom: 1px
        width: 100px
        text-align: right
        padding-right: rem(15)
        border: 0
        outline: 0
        text-transform: uppercase
        font-size: rem(14)
        background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%) /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 35%) /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 35%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ) /* IE6-9 */

input[type=datetime-local]
    box-sizing: border-box
    margin: 0
    width: 98%
    height: rem(45)
    padding: 0 rem(15)

.credit-card
    position: relative

    img
        display: block
        position: absolute
        top: rem(48)
        left: rem(10)


    input
        padding-left: rem(45)

button
    border: none
    background: none
    outline: none
    font-family: "Inter"
    cursor: pointer
    padding: 0
