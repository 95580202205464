.spinner-v2
    animation: spin 1.5s linear infinite
    max-width: 52px
    max-height: 52px
    margin: auto

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
