@import "./mixins";
@import "./kroger-colors";

$color_schemes: (
  "default": (
    "primary": $darkgray,
    "callout": $red,
    "callout2": $orange,
    "positive": $green,
    white: white,
    black: black,
    red: red,
    currentColor: currentColor
  )
);

@function get-color($scheme, $color) {
  @if not map-has-key($scheme, $color) {
    //@warn "Key not found #{$color}";
    @return $color;
  }
  @return map-get($scheme, $color);
}

@mixin colorAttr($attr, $color, $addlValue: "") {
  @each $name, $scheme in $color_schemes {
    @if $name == "default" {
      #{$attr}: #{$addlValue} get-color($scheme, $color)
    }
    @else if map-has-key($scheme, $color){ // Only override if it's a schemed color
      body.#{$name}-kitchen & {
        #{$attr}: #{$addlValue} get-color($scheme, $color)
      }
    }
  }
}
