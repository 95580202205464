@import "../sass/color-schemes"
@import "mixins"

.btn
    font-size: rem(15)
    line-height: em(18, 15)
    @include colorAttr("color", primary)
    text-decoration: none
    text-align: center
    max-width: 54%
    min-width: rem(186)
    transition: opacity 250ms ease

    &.disabled
        cursor: not-allowed
        transition: none
        filter: alpha(opacity=65)
        -webkit-box-shadow: none
        opacity: .65

        &:hover
            opacity: .65

    &:hover
        opacity: 0.8

    .icon
        vertical-align: middle
        margin: -.35em .5em 0 0

    &-standalone
        padding: rem(8) rem(14)
        border-radius: 1px
        @include colorAttr("border", $gray, "1px solid")
        display: block
        margin: 0 auto rem(20)

        &.btn-primary
            @include colorAttr("border", callout, "1px solid")

    &-dark
        @include colorAttr("background", primary)

    &-primary
        @include colorAttr("background", callout)
        @include colorAttr("color", white)

    &-outline
        box-shadow: 0px 0px 0px 1px $darkergray inset
        border: none
        border-radius: 2px
        min-width: 0
        @include colorAttr("color", $darkergray)
        background-color: transparent

    &-cta
        @include colorAttr("color", white)
        text-transform: uppercase
        border: none
        border-radius: 0
        margin: 0
        font-weight: 900
        padding: em(18, 15) 0
        width: 50%
        min-width: 0
        display: block
        float: left
        &.btn-outline
            @include colorAttr("color", $darkergray)

        &.btn-cta-full
            width: 100%
            max-width: 100%
            min-width: 100%

            +bp(640up)
                width: 94%
                max-width: 94%
                min-width: 94%
                margin-left: 3%

        & + &
            @include colorAttr("border-left", white, "1px solid")
.success-modal
    border-radius: rem(6)
    padding: rem(15) rem(26)
    background-color: rgba(39,42,44,95)
    @include colorAttr("color", white)
    width: rem(200)
    position: fixed
    top: 50%
    left: 50%
    transform: translate(-50%, -50%) scale(0.75)
    opacity: 0
    visibility: hidden
    z-index: 1001
    transition: all 340ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
    +bp(700up)
        position: absolute
    &.md-show
        visibility: visible
    &-inner
        .emoji
            position: absolute
            top: 50%
            left: rem(20)
            transform: translate(0, -50%)
            font-size: rem(21)
        .message
            padding-left: rem(30)
.success-modal-overlay
    position: fixed
    visibility: hidden
    background: rgba(0,0,0,0.5)
    z-index: 1000
    top: 0
    left: 0
    right: 0
    bottom: 0
    opacity: 0
    transition: all 340ms cubic-bezier(0.175, 0.885, 0.32, 1.275)
    +bp(700up)
        position: absolute

.show
    opacity: 1
    transform: translate(-50%, -50%) scale(1)
    visibility: visible
.show ~ .success-modal-overlay
    opacity: 1
    visibility: visible
.modal

    &-dialog
        vertical-align: middle
        display: table-cell

        &.modal-dialog-full
            height: 100%
            display: block
            margin: 0

            +bp(640up)
                height: auto
                display: table-cell
                vertical-align: middle

    &-header
        text-align: center
        border-bottom: none

        .close
            border: none
            background: transparent
            position: absolute
            right: 6px
            top: 15px

    &-title
        font-family: "Work sans"
        font-size: rem(17)
        line-height: em(20, 17)
        text-transform: uppercase

    &-body
        text-align: center

        p
            @include colorAttr("color", $midgray)
            font-size: rem(15)
            line-height: em(20,15)

            &.form-message
                font-size: rem(12)
                @include colorAttr("color", $midgray)
                line-height: em(15,12)

    &-content
        border: none
        width: 80%
        margin: 0 auto

        +bp(640up)
            width: rem(500)

        &.modal-full
            width: 100%
            height: 100%
            border-radius: 0
            background-color: rgba(255,255,255, 0.96)

            +bp(640up)
                height: auto
                min-height: rem(500)
                width: rem(500)

            .modal-header
                position: relative
                z-index: 999

                .close
                    font-size: rem(15)
                    font-weight: 500

                [title=Back]
                    path
                        @include colorAttr("fill", primary)

            .modal-body

                .list
                    margin: 0 0 50px

                    +bp(640up)
                        margin: 0

                .btn-next
                    margin-left: -15px

                    +bp(640up)
                        margin: 0 auto rem(18)

                        &.btn-create
                            width: 100%
                            max-width: 100%
                            margin-top: rem(12)
                            margin-bottom: rem(22)

                .list-item-header
                    width: 100%

                &.step-one
                    margin-top: rem(50)

                &.step-three
                    margin-top: rem(50)


                +bp(640up)
                    padding: 0
                    margin-top: 0
                    width: 90%
                    margin: 0 auto

                    .tab-content
                        max-height: 360px
                        overflow: scroll

                    fieldset
                        & > .list-item
                            padding: 0.5rem 0 0.5rem
                            width: 100%
                            margin-left: 0

                p
                    padding-left: 5%
                    padding-right: 5%

                    +bp(640up)
                        padding: 0

    &-footer
        padding: 0

    .btn
        text-transform: uppercase
        font-weight: 600
        font-family: "Work Sans"
        height: rem(48)
        border: none
        display: block
        padding: rem(15) 0
        margin: 0 auto
        min-width: 80%

        &-default
            background: transparent
            @include colorAttr("color", callout)
            @include colorAttr("border-color", $lightgray)

        &-next
            width: 100%
            max-width: 100%
            position: absolute
            bottom: 0
            display: block
            padding: rem(15) 0

            +bp(640up)
                position: static
                margin-top: rem(22)
                width: 80%
                min-width: 80%
                max-width: 80%

    form
        height: 100%

    .carousel
        height: 100%

    .carousel-inner
        height: 100%

    .item
        height: 100%

    &.in
        padding: 0!important
        margin: 0
        display: table!important
        height: 100%
        min-width: 100%

    .list
        text-align: left

.group-select
    height: rem(39)
    display: block

.icon-add

    svg
        vertical-align: middle
        float: right

.group-name-field[type=text]
    border: none
    background: transparent
    width: 80%
    margin: 0 auto
    display: block
    @include colorAttr("border-bottom", $gray, "1px solid")
    font-size: rem(22)
    line-height: em(23,22)
    height: auto
    padding: em(11,22) 0

.tab
    list-style: none
    display: inline-block
    padding: 0
    margin: 0
    width: rem(120)
    background: transparent
    @include colorAttr("border", primary, "1px solid")
    margin-left: -10px

    a
        padding: rem(7) 0
        display: block
        font-size: rem(13)
        line-height: rem(16)

    &.active
        @include colorAttr("background", primary)

        a
            @include colorAttr("color", white)

    &:first-of-type
        margin-left: 0

    &.payment-tab
        @include colorAttr("border-color", #C6CBD4)
        height: rem(55)
        margin-left: -5px
        border-left: none
        transition: border 250ms ease

        &:hover
            border-color: shade(#C6CBD4, 20%)


        &:first-of-type
            @include colorAttr("border-left", #C6CBD4, "1px solid")

        a
            padding: rem(19) 0

            img
                margin-top: rem(-3)

        &.active
            @include colorAttr("background", white)
            @include colorAttr("border-color", callout)

            &:hover
                border-color: shade($red, 15%)

            a
                @include colorAttr("color", callout)

            &:nth-of-type(1n+2)
                @include colorAttr("border-left", callout, "1px solid")
                margin-left: -6px

.share-dropdown
    width: 210px
    right: 5%
    left: 50%
    margin-left: -105px
    padding-top: 0
    top: 45px

    +bp(820up)
        width: 210px
        left: 50%
        right: auto
        top: 110%
        margin-left: -105px
        padding-top: 0

    &:before,
    &:after
        content: ""
        display: block
        position: absolute
        top: -5px
        left: 50%
        margin-left: -5px
        @include triangle(10px, #EBECEE, up)

        +bp(820up)
            top: -5px
            left: 50%
            margin-left: -5px
            @include triangle(10px, #EBECEE, up)

    .list-item-header
        margin: 0
        width: 100%
        text-align: left

    .social-icon
        width: 100%
        border-left: none
        border-right: none
        padding: rem(8) 3.3%
        margin-left: 0!important
        transition: background 250ms ease, color 250ms ease

        &:hover
            background: tint($lightgray, 80%)
            @include colorAttr("color", $darkergray)

        svg
            vertical-align: middle
            margin-right: rem(8)
            width: rem(24)

        &.twitter,
        &:facebook
            svg
                path
                    transition: fill 250ms ease

        &.twitter:hover
            svg
                path
                    fill: tint(#38ACED, 20%)

        &.facebook:hover
            svg
                path
                    fill: tint(#3B5998, 20%)

    &-container

        +bp(820up)
            clear: none
            float: right
            display: inline-block
            width: auto
            padding-right: 3%

        .btn.btn-standalone.btn-desktop
            width: 100%
            display: block
            float: none
            min-width: 100%

        &-desktop
            display: none

            +bp(820up)
                display: inline-block

.nav-tabs
    text-align: center
    padding-left: 0

.recent-item

    .list-item-copy-container

        +bp(820up)
            padding-top: rem(2)
            float: none!important

.payment-tabs
    margin-top: 5rem

    +bp(640up)
        margin-top: 1rem
