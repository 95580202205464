@import "../sass/color-schemes"
.location-bar-container
    @include bp(640up)
        padding: 0

body
    @include bp(640up)
        @include colorAttr("background", #fafbfc)

.container
    width: 100%
    margin-bottom: rem(54)
    &::after
        content: ''
        display: block
        clear: both
    @include bp(640up)
        max-width: rem(960)
        margin: 0 auto

.main

    @include bp(960up)
        float: left
        width: 74.468%
        margin: rem(40) 0 rem(60)
        position: relative
        @include colorAttr("background", white)
        @include colorAttr("border", #CCCCCC, "1px solid")

        .page-title
            margin: rem(22) 3% rem(27)


    .main-content
        padding: rem(13)

.one-col
    .main
        float: none
        margin-left: auto
        margin-right: auto
        @include bp(640up)
            margin-top: 60px

.sidebar
    display: none
    @include bp(960up)
        display: block
        float: left
        width: 31.27659%
        margin: rem(40) 0 0 4.255319%

    @include bp(960up)
        width: 21.27659%

.page-title
    margin-top: 100px
    font-size: rem(32)
    font-family: "Work Sans"
    line-height: 1
    text-align: center
    @include bp(960up)
        text-align: left
        display: block
        margin: 0
