@import "../sass/color-schemes"

*
    box-sizing: border-box
    -webkit-font-smoothing: antialiased

*[data-badge]::after
    content: attr(data-badge)
    font-size: rem(10)
    line-height: em(17,10)
    padding: 0 em(6,10)
    display: block
    position: absolute
    @include colorAttr("color", white)
    border-radius: em(10,10)
    z-index: 99

a
    text-decoration: none
    @include colorAttr("color", primary)
body
    font-family: "Inter"
    font-weight: 400
    margin: 0
    padding: 0!important
    height: 100%
    overflow: auto
    margin: rem(70) 0 0

.page-name
    font-family: "Work Sans"
    font-size: rem(24)
    font-weight: 800
    @include colorAttr("color", primary)
    line-height: 1.25
    text-align: center
    margin: rem(17) auto 0

.intro-copy
    font-size: rem(15)
    @include colorAttr("color", #6C6F73)
    line-height: 1.466666667
    text-align: center
    padding: 0 5%

.hero-image
    width: 100%
    height: auto
    margin-bottom: rem(-5)

.list
    padding: 0
    margin: 0
    @include clearfix

    &+&
        // margin-top: rem(-108)

        +bp(640up)
            margin-top: 0

        @include bp(820up)
            margin-top: 0

    +bp(640up)
        margin: 0 0 rem(12)

    @include bp(820up)
        padding-left: 3%
        padding-right: 3%
        margin: 0

    &-group
        margin: 0
        padding: 0 0 0 rem(26)
        @include colorAttr("border-top", $lightgray, "1px solid")

        +bp(640up)
            padding: 0

        .list-item
            padding-left: 0
            height: rem(39)

            +bp(640up)
                padding-left: 3%

                &:nth-of-type(2n)
                    @include colorAttr("border-left", $lightgray, "1px solid")
                    padding-right: 3%
                    clear: none

                &:nth-of-type(2n+1)
                    margin-left: 0
                    clear: left
                    padding-left: 3%
            +bp(820up)
                padding: 8px 3%!important

.list-item
    padding: rem(8) 5% rem(8)
    display: block
    float: none
    clear: both
    width: 100%
    height: auto
    margin: 0 0 0
    position: relative
    @include clearfix

    +bp(640up)
        padding: rem(16) 3.3% rem(16)

    @include bp(820up)
        width: 50%
        margin-left: 0
        float: left
        clear: none
        display: inline-block
        position: relative
        @include colorAttr("border-top", $lightgray, "1px solid")
        padding-left: 3%
        padding-right: 0
        box-sizing: border-box
        margin-top: -1px
        @include clearfix

        &:nth-of-type(2n+1)
            @include colorAttr("border-right", $lightgray, "1px solid")
            padding-left: 0
            padding-right: 3%
            clear: left

        &:nth-of-type(2n)
            @include colorAttr("border-bottom", $lightgray, "1px solid")
            @include colorAttr("border-left", $lightgray, "1px solid")
            margin-left: -1px

        &:last-of-type
            border-bottom: none

        &.full-width
            width: 100%
            border-right: none
            border-left: none
            padding-left: 0
            padding-right: 0
            clear: left
            float: none!important

    & + &
        @include colorAttr("border-top", $lightgray, "1px solid")

    &.disabled
        opacity: 0.6

    &.cc-number
        position: relative
        padding: rem(8) 0 rem(8)
        margin: 0

        +bp(820up)
            width: 50%
            border-bottom: none
            padding-right: 0!important
            border-right: none!important

        span.camera-icon
            position: absolute
            top: rem(49)
            right: 0
            margin-right: rem(12)
            +bp(820up)
                margin-right: rem(12)

        & + .list-item
            border-top: none

    &-header
        padding: rem(4) 5%
        text-transform: uppercase
        font-family: "Work Sans"
        font-weight: 600
        font-size: rem(14)
        @include colorAttr("color", primary)
        @include colorAttr("background", $lightgray)
        line-height: 1.428571429
        margin: 0
        width: 100%
        border-top: none

        @include bp(820up)
            margin-left: -3.1%
            margin-right: -3.3%
            padding-left: 3.3%
            display: block
            width: 106.3%

            &:last-of-type,
            &:nth-of-type(2n+1)
                margin-bottom: 0
                padding-left: 3.3%

        & + .list-item
            border-top: none

        .secondary-header
            float: right
            text-align: right
            font-size: rem(13)
            font-weight: 300
            @include colorAttr("color", $gray)
            line-height: em(20,13)
            text-transform: capitalize

    &-customer-name
        font-size: rem(14)
        @include colorAttr("color", #74777B)
        line-height: em(18,14)
        font-weight: 400
        margin: 0 0 em(4,14)

    &-tags
        padding: 0
        margin: 0
        margin-top: rem(10)
        list-style: none
        display: inline-block

    &-tag
        display: block
        span
            @include colorAttr("border", $lightgray, "1px solid")
            border-radius: 2px
            margin: 0 rem(7) rem(7) 0
            padding: em(4,12) em(7,12) em(4,12)
            font-size: rem(12)
            @include colorAttr("color", #000)
            line-height: 1em
            display: inline-block
            &::before
                content: url(../img/icons-plus.svg)
                display: block
                margin: 0px em(5, 12) 0 0
                float: left

    &-image
        width: 20%
        height: auto
        margin-right: 5%
        float: left
        display: inline-block

        &.avatar
            border-radius: 50%
            width: 15%
            position: relative

            +bp(640up)
                width: 10%
                margin-right: 3%

        &-checkout
            width: 20%
            height: auto
            padding-right: 5%
            float: left
            display: inline-block

            +bp(820up)
                width: 8.2%
                padding-right: 0
                margin-right: 02.3%

        &-wrapper
            position: relative
            width: 20%
            margin-right: 5%
            float: left

            +bp(640up)
                margin-right: 3%

            &.avatar
                width: 15%

                +bp(640up)
                    width: 10%

            &.checkout
                width: 15%

                +bp(820up)
                    width: 8.2%
                    padding-right: 0
                    margin-right: 02.3%

            .group-avatar-secondary
                width: 65%
                position: absolute
                right: rem(-7)
                bottom: rem(-3)
                border-radius: 50%
                @include colorAttr("border", white, "2px solid")

            .list-item-image
                float: none
                width: 100%
                display: block

            .list-item-image-checkout
                width: 100%
                padding: 0
                float: none
                display: block

            &[data-badge]::after
                content: attr(data-badge)
                font-size: rem(10)
                line-height: em(17,10)
                padding: 0 em(6,10)
                display: block
                position: absolute
                @include colorAttr("background-color", #000)
                @include colorAttr("color", white)
                border-radius: em(10,10)
                top: em(-3,10)
                left: em(-3,10)
                z-index: 98

    &-headline
        font-family: "Work Sans"
        font-weight: 600
        font-size: rem(17)
        @include colorAttr("color", #272A2C)
        line-height: 1.176470588
        margin: 0 0 rem(4)
        max-width: 85%

        @include bp(820up)
            max-width: 88%

    &-copy
        font-size: rem(14)
        font-weight: 400
        @include colorAttr("color", #74777B)
        line-height: 1.285714286
        max-height: rem(36)
        width: 100%
        overflow: hidden
        text-overflow: ellipsis
        margin: 0
        display: inline-block
        max-width: 100%
        display: -webkit-box
        -webkit-line-clamp: 2
        -webkit-box-orient: vertical


        &-container
            display: block
            width: 75%
            float: left

    &-details-container
        display: block
        float: left
        width: 100%
        padding-left: rem(15)
        padding-right: rem(15)
        position: relative

    &-option
        display: none

        +bp(820up)
            display: inline-block
            text-align: left
            width: 14%
            @include colorAttr("color", $midlightgray)
            text-decoration: underline

    &-subtotal
        display: none

        +bp(820up)
            display: inline-block
            width: 20%
            @include colorAttr("color", callout)
            margin: 0

    &-price
        font-size: rem(17)
        font-weight: 500
        @include colorAttr("color", callout)
        line-height: 1.176470588
        width: 10%
        display: inline-block
        text-align: right
        padding: 0
        margin: 0
        position: absolute
        right: 5%

    &-price,
    &-subtotal
        +bp(640up)
            text-align: right

    &-price,
    &subtotal,
    &-option
        +bp(640up)
            font-size: rem(14)
            line-height: em(18,14)

.headline
    font-family: "Work Sans"
    font-size: rem(20)
    line-height: em(22,20)
    text-transform: uppercase
    margin-bottom: 0

    &-modal-full
        font-size: rem(28)
        line-height: em(30,28)

.disabled
    opacity: .6

.center
    text-align: center


.loading1
    height: rem(10)
    @include colorAttr("background", $midgray)
    width: 80%
    border-radius: rem(10)
    margin-bottom: rem(14)

.loading2
    height: rem(8)
    @include colorAttr("background", $lightgray)
    width: 100%
    border-radius: rem(8)
    margin-bottom: rem(5)

.loading3
    width: 70%
    height: rem(8)
    @include colorAttr("background", $lightgray)
    border-radius: rem(8)
    margin-bottom: rem(5)

.loading-item-small
    .loading1
        width: 40%
    .loading3
        width: 30%

.no-scroll
  overflow: hidden
