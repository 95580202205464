$headfont: "Work Sans"
$textfont: "Inter"

// header weights
.h-700
    font-family: $headfont
    font-weight: 700

.h-600
    font-family: $headfont
    font-weight: 600

.h-500
    font-family: $headfont
    font-weight: 500

// paragraph weights
.p-700
    font-family: $textfont
    font-weight: 700

.p-600
    font-family: $textfont
    font-weight: 600

.p-400
    font-family: $textfont
    font-weight: 400

// header sizes
.h-xxxl
    font-size: 2.875rem
    line-height: 3.375rem

.h-xxl
    font-size: 2.25rem
    line-height: 2.625rem

.h-xl
    font-size: 1.75rem
    line-height: 2.0625rem

.h-lg
    font-size: 1.625rem
    line-height: 1.875rem

.h-md
    font-size: 1.5rem
    line-height: 1.75rem

// paragraph sizes
.p-lg
    font-size: 	1.0625rem
    line-height: 1.3125rem

.p-md
    font-size: 0.9375rem
    line-height: 1.125rem

.p-sm
    font-size: 0.75rem
    line-height: 0.9375rem

.p-xs
    font-size: 0.5rem
    line-height: 0.625rem

// classnames
.head-xxxl
    @extend .h-700
    @extend .h-xxxl

.head-xxl
    @extend .h-700
    @extend .h-xxl

.subhead-xxl
    @extend .h-500
    @extend .h-xxl

.head-xl
    @extend .h-600
    @extend .h-xl

.head-lg
    @extend .h-700
    @extend .h-lg

.subhead-lg
    @extend .h-500
    @extend .h-lg

.head-md
    @extend .h-600
    @extend .h-md

.text-lg
    @extend .p-400
    @extend .p-lg

.text-b-lg
    @extend .p-600
    @extend .p-lg

.text-xb-lg
    @extend .p-700
    @extend .p-lg

.text-md
    @extend .p-400
    @extend .p-md

.text-b-md
    @extend .p-600
    @extend .p-md

.text-xb-md
    @extend .p-700
    @extend .p-md

.text-sm
    @extend .p-400
    @extend .p-sm

.text-b-sm
    @extend .p-600
    @extend .p-sm

.text-xb-sm
    @extend .p-700
    @extend .p-sm

.text-xs
    @extend .p-700
    @extend .p-xs



