@import "mixin/prefixer", "mixin/transition", "mixin/triangle", "mixin/rem" , "mixin/em", "mixin/shade", "mixin/tint"

$darkgray: #2C2F31
$darkergray: #535861
$gray: #A7AFBD
$midgray: #7E8692
$midlightgray: #74777B
$lightgray: #EBECEE
$lightestgray: #FCFBFA
$yellow: #EFAF02
$red: #F25F23
$orange: #F25F23
$green: #4FCD49

// brand
$brandorange: #FC4C02
$brandred: #FC4C02
$brandhover: #F8743D
$cream: #F8E0D4
$plum: #3F0005
$mediumrare: #841E10

// secondary
$redalert: #EF5350
$brandyellow: #F6B724
$brandgreen: #19A059
$driverblue: #546EB0

// UI
$branddarkergray: #282828
$branddarkgray: #494949
$brandgray: #7E8692
$darkoutline: #A3AFBD
$outline: #DFE7EC
$dividers: #E0E0E0
$lightbase: #EFF4F8

=bp($point)
    @if $point == 400up
        @media (min-width: rem(400))
            @content
    @if $point == 540up
        @media (min-width: rem(540))
            @content
    @else if $point == 640up
        @media (min-width: rem(640))
            @content
    @else if $point == 700up
        @media (min-width: rem(700))
            @content
    @else if $point == 820up
        @media (min-width: rem(820))
            @content
    @else if $point == 900up
        @media (min-width: rem(900))
            @content
    @else if $point == 960up
        @media (min-width: rem(960))
            @content
    @else if $point == 1000up
        @media (min-width: rem(1000))
            @content

@mixin clearfix
  &::after
    clear: both
    content: ""
    display: table
